import {
  getStandardErrorObject,
  getTransactionId,
  logError,
  logInfo,
  setCookie,
} from './utils/Utils';
import { ClientErrors } from './utils/ClientErrors';
import { Events } from './utils/Events';
import { doGuestLoginApiCall } from './utils/GuestLogin';

const COOKIE_LOCK_SSO = 'sfly_sso_lock';

// Used to return the initAuth promise if integrators call it more than once.
window.initZuulPromise = null;

// Used to return the evalUseNewAuth promise if integrators call it more than once.
window.evalUseNewAuthPromise = null;

/**
 * Loads the library. Clients can call this directly if they want to by pass the A/B split
 */
window.initSflyAuth = function () {
  if (window.$sflyAuth) {
    return new Promise((resolve) => {
      resolve();
    });
  } else if (window.initZuulPromise === null) {
    // If we have not started a promise, we create one and will return it. If one exists we use the one that is still unresolved.
    window.initZuulPromise = new Promise((resolve, reject) => {
      import(/* webpackChunkName: "auth" */ './utils/AuthClient.js').then(
        () => {
          window._sflyAuthClient
            .init()
            .then(() => {
              // Set the $sflyAuth window object for implementing clients to use.
              window.$sflyAuth = window._sflyAuthClient;
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        },
      );
    });
  }
  // Return the newly created promise or the one we already start in a subsequent call.
  return window.initZuulPromise;
};

/**
 * Lets a client know if they should or should not use the new SSO signin.
 * @deprecated Please use window.initSflyAuth instead
 * @returns {Promise<any>}
 */
window.evalUseNewAuth = () => {
  // eslint-disable-next-line
  console.log(
    'WARNING: Please consider switching to window.initSflyAuth() instead or window.evalUseNewAuth().',
  );

  // If $sflyAuth is not null then, it evaled to true at some point so just resolve true.
  if (window.$sflyAuth) {
    return Promise.resolve(true);
  } else if (window.evalUseNewAuthPromise === null) {
    // If we have not started a promise, we create one and will return it. If one exists we use the one that is still unresolved.
    window.evalUseNewAuthPromise = new Promise((resolve, reject) => {
      // TODO: Remove this cookie check after Q3
      // This is to clean up legacy IP1/IP2 A/B Split test stuff. Probably can be removed in Q3
      const lockSsokMatch = document.cookie.match(
        new RegExp('(^| )' + COOKIE_LOCK_SSO + '=([^;]+)'),
      );
      if (lockSsokMatch) {
        const d = new Date();
        d.setTime(d.getTime() - 60000);
        let host = window.location.hostname;
        const match = host.match(/(dev|beta|stage|kappa)/i);
        if (match) {
          host = host.substring(match.index);
        } else {
          host = '.shutterfly.com';
        }
        setCookie(COOKIE_LOCK_SSO, 'true', d, host);
      }

      window
        .initSflyAuth()
        .then(() => {
          //eslint-disable-next-line
          console.log('Eval: Using the Full IP2 Experience. Enjoy!');
          resolve(true);
        })
        .catch((e) => {
          // Get a transId to link errors from the client to splunk
          const transId = getTransactionId();
          logError(ClientErrors.INITIALIZATION, e, transId);
          reject(getStandardErrorObject(ClientErrors.INITIALIZATION, transId));
        });
    });
  }
  // Return the newly created promise or the one we already start in a subsequent call.
  return window.evalUseNewAuthPromise;
};

window.guestLogin = (redirectUri) => {
  // If $sflyAuth is null then, call guest flow apis.
  return new Promise((resolve, reject) => {
    if (!redirectUri) {
      const transId = getTransactionId();
      logError(ClientErrors.GUEST_REDIRECT_URI_NOT_FOUND, transId);
      reject(
        getStandardErrorObject(
          ClientErrors.GUEST_REDIRECT_URI_NOT_FOUND,
          transId,
        ),
      );
      return;
    }
    window
      .initSflyAuth()
      .then(() => {
        window.$sflyAuth
          .getIdToken()
          .then(() => {
            const transId = getTransactionId();
            logError(ClientErrors.GUEST_USER_ALREADY_LOGGED_IN, transId);
            reject(
              getStandardErrorObject(
                ClientErrors.GUEST_USER_ALREADY_LOGGED_IN,
                transId,
              ),
            );
          })
          .catch((e) => {
            doGuestLoginApiCall(redirectUri);
          });
      })
      .catch((e) => {
        // Get a transId to link errors from the client to splunk
        const transId = getTransactionId();
        logError(ClientErrors.GUEST_LOGIN_INITIALIZATION, e, transId);
        reject(
          getStandardErrorObject(
            ClientErrors.GUEST_LOGIN_INITIALIZATION,
            transId,
          ),
        );
      });
  });
};

logInfo('ZUUL_JS_LOADED');

// Dispatch an event to let clients know the library has been loaded into the browser for cases where zuul is loaded after other scripts
document.dispatchEvent(new CustomEvent(Events.EVAL_READY)); // Legacy (Deprecated)
document.dispatchEvent(new CustomEvent(Events.INIT_READY));
