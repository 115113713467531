export const ClientErrors = {
  NOT_LOGGED_IN: {
    code: '000',
    splunkLabel: 'NOT_LOGGED_IN',
    message: 'Not signed in to sso.',
  },
  INITIALIZATION: {
    code: '001',
    splunkLabel: 'INITIALIZATION',
    message: 'Error initializing the client library.',
  },
  IAM_SYNC: {
    code: '002',
    splunkLabel: 'IAM_SYNC',
    message: 'Error retrieving iam configuration.',
  },
  TOKEN_SYNC: {
    code: '003',
    splunkLabel: 'TOKEN_SYNC',
    message: 'Error syncing login state with the sso server.',
  },
  INVALID_COGNITO_SESSION: {
    code: '004',
    splunkLabel: 'INVALID_COGNITO_SESSION',
    message: 'Error retrieving a valid session token from cognito.',
  },
  APP_SERVER_SESSION_POLL: {
    code: '006',
    splunkLabel: 'APP_SERVER_SESSION_POLL',
    message: 'Error communicating with app server.',
  },
  TEST_AND_TARGET_BAILOUT: {
    code: '007',
    splunkLabel: 'TEST_AND_TARGET_BAILOUT',
    message: 'Bailed out on Test and Target.',
  },
  EXTEND_PERSIST_COOKIE_ERROR: {
    code: '008',
    splunkLabel: 'EXTEND_PERSIST_COOKIE_ERROR',
    message: 'Error communicating with sso server to extend cookie life.',
  },
  CHECK_COOKIE_EXISTENCE: {
    code: '009',
    splunkLabel: 'CHECK_COOKIE_EXISTENCE',
    message: 'Error communicating with sso server to check cookie life.',
  },
  APP_SVR_START_NO_TOKEN: {
    code: '010',
    splunkLabel: 'APP_SVR_START_NO_TOKEN',
    message: 'Error trying to start the app server without a refreshToken.',
  },
  APP_SERVER_SESSION_QUERY: {
    code: '011',
    splunkLabel: 'APP_SERVER_SESSION_QUERY',
    message: 'Error communicating with app server.',
  },
  CURRENT_AUTH_USER_EXCEPTION: {
    code: '012',
    splunkLabel: 'CURRENT_AUTH_USER_EXCEPTION',
    message: 'Amplify error while calling currentAuthenticatedUser method.',
  },
  CURRENT_USER_EMAIL_MISMATCH: {
    code: '013',
    splunkLabel: 'CURRENT_USER_EMAIL_MISMATCH',
    message:
      'User mismatch on getIdToken call. Zuul would have called window.reload() for this condition.',
  },
  COOKIE_HTML_DATA_ERROR: {
    code: '014',
    splunkLabel: 'COOKIE_HTML_DATA_ERROR',
    message: 'Received something other than an email from postMessage.',
  },
  TRACKER_FAILED: {
    code: '015',
    splunkLabel: 'TRACKER_FAILED',
    message: 'Code for user tracking info had an uncaught error.',
  },
  SIGN_NO_REDIRECT_RESYNC_TOKENS_FAILED: {
    code: '016',
    splunkLabel: 'SIGN_NO_REDIRECT_RESYNC_TOKENS_FAILED',
    message: 'The execution of resyncTokens from the sign in popup failed.',
  },
  SIGN_NO_REDIRECT_RESYNC_TOKENS_UNRESOLVED: {
    code: '017',
    splunkLabel: 'SIGN_NO_REDIRECT_RESYNC_TOKENS_UNRESOLVED',
    message:
      'The execution of resyncTokens from the sign in popup seem to have never resolved.',
  },
  GUEST_LOGIN_FAILED: {
    code: '018',
    splunkLabel: 'GUEST_LOGIN_FAILED',
    message: 'Error in Guest login api.',
  },
  GUEST_TOKEN_SYNC: {
    code: '019',
    splunkLabel: 'GUEST_TOKEN_SYNC',
    message: 'Error syncing guest token for coookies creation.',
  },
  GUEST_USER_ALREADY_LOGGED_IN: {
    code: '020',
    splunkLabel: 'USER_ALREADY_LOGGED_IN',
    message: 'User is already loggedin while Guest login',
  },
  GUEST_REDIRECT_URI_NOT_FOUND: {
    code: '021',
    splunkLabel: 'GUEST_REDIRECT_URI_NOT_FOUND',
    message: 'Please provide Guest redict uri.',
  },
  GUEST_LOGIN_INITIALIZATION: {
    code: '021',
    splunkLabel: 'GUEST_LOGIN_INITIALIZATION',
    message: 'Error initializing the client library.',
  },
};
