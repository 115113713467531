// import axios from 'axios';
// import {BASE_URL_WWW} from './Utils';
/*
 * This module should be used by importing the default function and passing
 * an AuthClient object as the argument.  When this function is called it
 * will call specified functions to set the data layers of the specific
 * tracker.
 * The other functions are meant to be used internally but are exported for
 * unit testing purposes.
 */

export const trackingHostnameRecipes = {
  hostMap: {
    // hostMap sets a list of hostnames that are matched to a
    // recipe label.  The recipe label than specifies the
    // execution to be used.
    Shutterfly: [/^(www|local)\.(:?.*\.)?shutterfly\.com/],
  },

  recipes: {
    Shutterfly: (currentUser, visitorId, isProspect) => {
      setShutterflyAdobeDTL(currentUser, visitorId, isProspect);
      setShutterflyTealium(currentUser, visitorId);
    },
  },
};

/* this function takes a hostname as an argument and applies it to the filter
 * list provided above.  Every match adds that label to a list.
 */
export const findRecipeLabels = (hostname) => {
  const resultStack = [];
  Object.entries(trackingHostnameRecipes.hostMap).forEach((mapFilter) => {
    const [label, filterList] = mapFilter;

    // quick search for an exact match
    if (filterList.includes(hostname)) {
      return resultStack.push(label);
    }

    // search for patterns
    filterList.every((filter) => {
      if (filter.constructor.name === 'RegExp' && hostname.match(filter)) {
        if (!resultStack.includes(label)) resultStack.push(label);
        // stop looking for a match
        return false;
      }

      // no match so keep going
      return true;
    });
  });

  return resultStack;
};

/* Function takes a array of labels with the different recipes that should be
 * executed for this domain.  The return result is an array of functions that
 * match the list.
 */
export const getRecipeExecutionList = (labelList) => {
  const resultStack = [];

  labelList.forEach((label) => {
    if (trackingHostnameRecipes.recipes[label]) {
      resultStack.push(trackingHostnameRecipes.recipes[label]);
    }
  });

  return resultStack;
};

/*
 * This function takes a user object or a null and returns a user id
 * if exists or a null if it does not exist.
 */
export const determineCustomerId = (currentUser) => {
  return currentUser && currentUser.sfly_uid ? currentUser.sfly_uid : null;
};

/*
 *  This takes the current user status and returns a string for the visitor type.
 */
export const determineVisitorType = (currentUser, isProspect) => {
  let test = isProspect
    ? 'prospect'
    : currentUser !== null
      ? currentUser && currentUser.user_type === 'GUEST'
        ? 'guest'
        : 'signedIn'
      : 'signedOut';
  return test;
};

/*
 * This function simplifies unit testing.
 */
export const setDtl = (name, dtl) => {
  window[name] = dtl;
};

/*
 *  This function sets the user information for Adobe DTL (SFLY_DTL) whenever a login status changes
 *  dtlNameOverride is optional argument mostly used for testing concurrently.
 */
export const setShutterflyAdobeDTL = (
  currentUser,
  visitorId,
  isProspect,
  dtlNameOverride,
) => {
  const customerId = determineCustomerId(currentUser) || '';
  const visitorType = determineVisitorType(currentUser, isProspect);

  const dtlName = dtlNameOverride ? dtlNameOverride : 'SFLY_DTL';

  const isVisitorInfoMissing = () => {
    return !window[dtlName] || !window[dtlName].session;
  };

  const isVisitorInfoDifferent = () => {
    return (
      window[dtlName].session.visitorDetails.customerId !== customerId ||
      window[dtlName].session.visitorDetails.visitorId !== visitorId ||
      window[dtlName].session.visitorDetails.visitorType !== visitorType
    );
  };

  // if SFLY_DTL is defined than set session information
  if (
    // only want to do this if we can see the page initialized DTL
    window[dtlName] &&
    // page init may not have included session info
    // if nothing has been defined than we will now.
    (isVisitorInfoMissing() ||
      // visitorDetails was defined, check if there is a difference
      // don't want to update for no changes.
      isVisitorInfoDifferent())
  ) {
    window[dtlName].session = {
      ...window[dtlName].session,
      visitorDetails: {
        customerId,
        visitorId,
        visitorType,
      },
    };

    // if on the accounts hostname swap it for the www domain
    // let hostName = window.location.hostname.replace('accounts', 'www');

    // fire and forget a call to update Bob cred cache
    // NO BOB
    // axios({
    //     method: 'POST',
    //     credentials: 'include',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     url: `${BASE_URL_WWW}/bob/creds/sync`,
    //     data: JSON.stringify({
    //         delayedCustomerIDForDTLOnly: customerId,
    //         visitorType,
    //     }),
    // });
  }

  // window[dtlName].session is now set up, has it been reported?
  if (
    window[dtlName] &&
    typeof window[dtlName].session.visitorDetails.reported === 'undefined' &&
    window._satellite
  ) {
    window[dtlName].session.visitorDetails.reported = true;
    window._satellite.track('visitorDetailsLoad', {
      customerId: window[dtlName].session.visitorDetails.customerId,
      visitorId: window[dtlName].session.visitorDetails.visitorId,
      visitorType: window[dtlName].session.visitorDetails.visitorType,
    });
  }
};

/*
 * This function sets the Tealium user information when ever the login status changes.
 *  dtlNameOverride is optional argument mostly used for testing concurrently.
 */
export const setShutterflyTealium = (
  currentUser,
  visitorId,
  dtlNameOverride,
) => {
  const customerId = determineCustomerId(currentUser);
  const dtlName = dtlNameOverride ? dtlNameOverride : 'MasterTmsUdo';

  // if not there than lets not do anything.
  if (window[dtlName]) {
    setDtl(dtlName, {
      ...window[dtlName],
      uid: customerId,
      userId: customerId,
      visitorId,
    });
  }
};

/*
 * Single function to be exported. Takes AuthClient as input and than sets the
 * upstream specified tracking data layers with the user information.
 */
export default function trackers(auth) {
  const currentUser = auth.getCurrentUser();
  const { isProspect, visitorId } = auth.getProspectInformation();
  // eslint-disable-next-line no-useless-escape
  const unwrappedVisitorId = visitorId ? visitorId.replace(/\"/g, '') : ''; // remove literal quotes in string
  const labelList = findRecipeLabels(window.location.hostname);
  getRecipeExecutionList(labelList).forEach((recipeExecutable) =>
    recipeExecutable(currentUser, unwrappedVisitorId, isProspect),
  );
}
