import Auth from '@aws-amplify/auth';

import {
  IAM_CONFIG_FALLBACK,
  BASE_URL_SSO,
  BASE_URL_WWW,
  // API_KEY_API2,
  // BASE_URL_API2,
  BASE_URL_TP,
  BASE_URL_SHARE,
  ENV,
  getStandardErrorObject,
  logError,
  getTransactionId,
  normalizeCognitoGroups,
  urlHelper,
  getCookieValue,
  logInfo,
  // isValidUrlForSessionRestart,
  getTokenBaseUrlByHostName,
  WINDOW_FEATURES_VALID_PROPERTIES,
  RESYNC_TOKENS_SUCCESS_SHOW_SIGN_IN_NO_REDIRECT,
  initiateInterceptor,
  BASE_URLS,
  // setCookie
} from './Utils';

import { MyStorage } from './MyStorage';
import { ClientErrors } from './ClientErrors';
import { Events } from './Events';
import Trackers from './Trackers';

const WAIT_TO_EXTEND_TOKENS = 10 * 60 * 1000; // 10 minutes
//const WAIT_TO_EXTEND_SESSION = 5*60*1000; // 5 minutes
const POLL_COOKIE_EXISTENCE = 10 * 1000; // 10 seconds
const COGNIO_TOKENS_LAST_EXTENDED_AT = 'sflySsoLastExtended';
const HTTP_SESSION_LAST_EXTENDED_AT = 'sflySessionLastExtended';

const QUERY_APP_SERVER_TIMEOUT = 'sflySsoQueryAppSvrTO';
const QUERY_APP_SERVER_TIMEOUT_DURATION = 3 * 60 * 1000; // 3 minutes

// const IAM_LOCAL_STORAGE_CACHE_TIME = 10*60*1000; // 10 minutes
// const IAM_LOCAL_STORAGE_CACHE_KEY = 'sflySsoIAMConfig';

const COOKIE_IFRAME_ID = 'sflySsoCookieFrame';
const COOKIE_IFRAME_HTML = '/v3/oauth2/cookies.html';

const BRAND_UID_KEYS = [
  'sfly_uid',
  'sfly3_uid',
  'data_center',
  'context',
  'mlt_uid',
  'preschools_uid',
  'ltsmiles_uid',
];

export class AuthClient {
  constructor() {
    this.currentUser = null;
    this.authTime = null;
    this.refreshToken = null;
    this.iamConfig = null;
    initiateInterceptor();
    console.log('resync for every 55 mints');
    setInterval(() => this.resyncTokens().then().catch(), 55 * 60 * 1000);
    // Handle the postMesage() from the cookie.html
    window.addEventListener(
      'message',
      (e) => {
        const { isTrusted, origin, data } = e;
        if (isTrusted && origin === BASE_URL_SSO) {
          if (this.currentUser !== null) {
            if (data === 'null') {
              // Clean up currentUser and other object upon cookie expiry
              this.currentUser = null;
              this.authTime = null;
              this.refreshToken = null;
              MyStorage.clear();
              document.dispatchEvent(new CustomEvent(Events.SESSION_LOGOUT));
            } else if (typeof data === 'string') {
              // USER-6291 Since we see messages other than ours on this we are checking the data a bit
              const decodedData = decodeURIComponent(data);
              if (
                decodedData.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g) !==
                  null &&
                this.currentUser.email !== decodedData
              ) {
                // When we see a mis-mismatch, normally customer service impersonation, we reload the window. I am not a fan of this approach so we are logging it to see how much it is happening.
                logError(
                  ClientErrors.CURRENT_USER_EMAIL_MISMATCH,
                  {
                    currentEmail: this.currentUser.email,
                    emailFromPostMessage: decodeURIComponent(data),
                  },
                  getTransactionId(),
                );
                window.location.reload();
              }
            }
          } else if (typeof data === 'object') {
            if (
              data.eventId &&
              typeof data.eventId === 'string' &&
              data.eventId.match('^[a-zA-Z]+$')
            ) {
              if (
                data.eventId === Events.SIGN_IN_POPUP_FORM_SUBMITTED_SUCCESS
              ) {
                document.dispatchEvent(
                  new CustomEvent(Events.CLOSE_SIGN_IN_POPUP),
                );
              }
              if (
                data.eventId === Events.SIGN_IN_POPUP_FORM_SUBMITTED_FAILURE
              ) {
                const transId = getTransactionId();
                logError(
                  Events.SIGN_IN_POPUP_FORM_SUBMITTED_FAILURE,
                  null,
                  transId,
                );
              }
            }
          }
        }
      },
      true,
    );

    // Load the iFrame cookie.html that will do the window.postMessage()
    var iframe = document.createElement('iframe');
    iframe.setAttribute(
      'style',
      'border: none; height: 0px; width: 0px; display: none;',
    );
    iframe.setAttribute('src', '');
    iframe.setAttribute('id', 'sflySsoCookieFrame');
    document.body.appendChild(iframe);
    console.log('sso-client - Enter constructor');
  }

  /**
   * Initialize and sync the Cognito in memory token store.
   * @returns {Promise<any>}
   */
  init() {
    // Track when the auth client is loaded
    logInfo('ZUUL_AUTH_CLIENT_LOADED');

    // if(ENV === 'production' || ENV === 'prod' || ENV === 'dev'){
    return new Promise((resolve) => {
      this.iamConfig = IAM_CONFIG_FALLBACK;
      this._configAuth();
      resolve();
    });
    // }
    // else {

    //     // Check local storage for cached IAM config and use that if it is still good.
    //     const cachedConfig = localStorage.getItem(IAM_LOCAL_STORAGE_CACHE_KEY);
    //     if(cachedConfig){
    //         const tempConfig = JSON.parse(cachedConfig);
    //         if(tempConfig.lastCacheDate && Date.now() - tempConfig.lastCacheDate < IAM_LOCAL_STORAGE_CACHE_TIME) {
    //             this.iamConfig = tempConfig;
    //             this._configAuth();
    //             document.dispatchEvent(new CustomEvent(Events.AUTH_CLIENT_LOADED));
    //             return Promise.resolve();
    //         }
    //     }

    //     // Configure Auth
    //     const requestOptions = {
    //         method: 'GET',
    //         headers: { 'sfly-apikey': API_KEY_API2[ENV] },
    //         url : `${BASE_URL_API2}/user/iamconfig/v1/client/web`,
    //     };

    //     return new Promise((resolve) => {
    //         axios(requestOptions)
    //             .then((response) => {
    //                 // Store the config for later usage.
    //                 this.iamConfig = Object.assign({lastCacheDate: Date.now()}, response.data);
    //                 // Cache the config for faster page reloads and less hits to the server
    //                 localStorage.setItem(IAM_LOCAL_STORAGE_CACHE_KEY, JSON.stringify(this.iamConfig));
    //                 this._configAuth();
    //             })
    //             .catch((e) => {
    //                 // Log an error for tracking purposes and tracking fail rates on this endpoint
    //                 const transId = getTransactionId();
    //                 logError(ClientErrors.IAM_SYNC, e, transId);
    //                 // Set the Auth config to the fallbacks we hardcode in the Utils.js
    //                 this.iamConfig = IAM_CONFIG_FALLBACK;
    //                 this._configAuth();
    //             }).finally(() => {
    //                 // We resolve not matter what since we fallback to acceptable defaults if things go bad with this endpoint.
    //                 document.dispatchEvent(new CustomEvent(Events.AUTH_CLIENT_LOADED));
    //                 resolve();
    //             });
    //     });

    // }
  }

  /**
   * Get the current user information
   * @returns {object}
   */
  getCurrentUser() {
    return this.currentUser;
  }

  /**
   * Return the prospective status of this user or browser session
   * @returns {object}
   */
  getProspectInformation() {
    const dataCookie = getCookieValue(process.env.DATA_COOKIE);
    const visitorId = getCookieValue(process.env.VISITOR_ID_COOKIE);
    const isProspect = this.currentUser === null && dataCookie === null;
    return {
      isProspect: isProspect,
      visitorId: isProspect || this.currentUser === null ? visitorId : '',
    };
  }

  /**
   * Returns a boolean based on the age of the tokens and if the user will require elevation.
   * If forceElevation is enabled it should always return true for all preprod environments.
   * @returns {boolean}
   */
  isElevationNeeded() {
    //let currentUser = typeof (this.currentUser) == 'undefined' ? null : this.currentUser;
    if (getCookieValue('forceElevation') === 'true' && ENV !== 'production') {
      return true;
    } else if (
      this.authTime &&
      this.iamConfig.elevationEnabled &&
      Date.now() - this.authTime * 1000 >
        this.iamConfig.elevationDuration * 1000
    ) {
      return true;
    }
    return false;
  }

  /**
   * Show the elevate sign in view at the SSO server. Does not redirect if user is already logged in
   *
   * @param configObject {
   *     redirectUri
   *     brand
   *     source
   * }
   *
   */
  async requireElevation(configObject) {
    //let idTokenPromiseResult = await this.getIdToken();
    window.location.assign(urlHelper(BASE_URL_SSO + '/elevate', configObject));
  }

  /**
   *
   * @param configObject {
   *     redirectUri - The url the SSO server should redirect to after the action
   *     brand - SFLY, TP, LT, etc.
   *     source - The context message of the brand. Brands can have more than one login or signup context like "Sign in to make a book".
   *     email
   *     cid
   * }
   *
   */
  showSignIn(configObject) {
    window.location.assign(urlHelper(BASE_URL_SSO + '/', configObject));
  }

  showSignInNoRedirect(
    configObject,
    statusCallback,
    windowId = 'ssosignin',
    windowFeatures,
    redirectFromSsoClient,
  ) {
    let windowFeaturesConfig = '';
    const isMobile = window.matchMedia(
      'only screen and (max-width: 480px)',
    ).matches;
    if (windowFeatures && typeof windowFeatures === 'object') {
      windowFeaturesConfig = Object.entries(windowFeatures).reduce(
        (result, [key, value]) => {
          if (WINDOW_FEATURES_VALID_PROPERTIES.includes(key)) {
            if (isMobile) {
              if (key === 'width' || key === 'innerWidth')
                value = window.innerWidth;
              if (key === 'height' || key === 'innerHeight')
                value = window.innerHeight;
              if (
                key === 'screenX' ||
                key === 'left' ||
                key === 'screenY' ||
                key === 'top'
              )
                value = 0;
            }
            result += `${key}=${value},`;
          }
          return result;
        },
        '',
      );
    }
    if (windowFeaturesConfig === '') {
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;
      const openerWidth = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : window.screen.width;
      const openerHeight = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : window.screen.height;
      const systemZoom = openerWidth / window.screen.availWidth;
      const defaultWidth = isMobile ? window.innerWidth : 500;
      const defaultHeight = isMobile ? window.innerHeight : 800;
      const left =
        (openerWidth - defaultWidth) / 2 / systemZoom + dualScreenLeft;
      const top =
        (openerHeight - defaultHeight) / 2 / systemZoom + dualScreenTop;
      windowFeaturesConfig = `innerWidth=${defaultWidth},innerHeight=${defaultHeight},left=${left},top=${top}`;
    }

    const ssoUrl = urlHelper(BASE_URL_SSO + '/', { popup: true });
    const signInPopUp = window.open('', windowId, windowFeaturesConfig);
    if (signInPopUp) {
      signInPopUp.location = ssoUrl;
      signInPopUp.focus();
    }

    const focusOpenerAndClose = () => {
      if (window.opener) window.focus();
      if (signInPopUp) signInPopUp.close();
    };

    const handleStatusCallback = (success, error) => {
      if (statusCallback && typeof statusCallback === 'function') {
        const payload = { success };
        if (error && error instanceof Error) payload.error = error;
        statusCallback(payload);
      }
    };

    const closeSignInPopUp = async () => {
      const transId = getTransactionId();
      let recursiveInitCall = 0;
      try {
        const results = await this._recursiveResyncTokens(recursiveInitCall);
        if (results) {
          logInfo(RESYNC_TOKENS_SUCCESS_SHOW_SIGN_IN_NO_REDIRECT);
          handleStatusCallback(true);
          focusOpenerAndClose();
        }
      } catch (error) {
        logError(
          ClientErrors.SIGN_NO_REDIRECT_RESYNC_TOKENS_FAILED,
          error,
          transId,
        );
        handleStatusCallback(false, error);
        if (redirectFromSsoClient && window.opener) {
          window.opener.location.assign(
            urlHelper(BASE_URL_SSO + '/', configObject),
          );
        }
        focusOpenerAndClose();
      }
      document.removeEventListener(
        Events.CLOSE_SIGN_IN_POPUP,
        closeSignInPopUp,
      );
    };

    document.addEventListener(Events.CLOSE_SIGN_IN_POPUP, closeSignInPopUp);
    return signInPopUp;
  }

  /**
   *
   * @param configObject {
   *     redirectUri - The url the SSO server should redirect to after the action
   *     brand - SFLY, TP, LT, etc.
   *     source - The context message of the brand. Brands can have more than one login or signup context like "Sign in to make a book".
   *     email
   *     givenName
   *     familyName
   *     contentSpace - Dynamic content well of the signup page
   *     cid - Campaign id for signup
   * }
   *
   */
  showSignUp(configObject) {
    window.location.assign(urlHelper(BASE_URL_SSO + '/signup', configObject));
  }

  /**
   *
   * @param configObject {
   *     redirectUri - The url the SSO server should redirect to after the action
   *     brand - SFLY, TP, LT, etc.
   *     source - The context message of the brand. Brands can have more than one login or signup context like "Sign in to make a book".
   *     email
   *     givenName
   *     familyName
   *     contentSpace - Dynamic content well of the signup page
   *     cid - Campaign id for signup
   * }
   *
   */
  showTilesHello(configObject) {
    window.location.assign(
      urlHelper(BASE_URL_SSO + '/tilesHello', configObject),
    );
  }

  /**
   *
   * @param configObject {
   *     brand - SFLY, MLT, PRESTIGE, PRESCHOOLS
   *     redirectUri - The url the SSO server should redirect to after the action
   * }
   */
  showChangeProfile(configObject) {
    window.location.assign(urlHelper(BASE_URL_SSO + '/profile', configObject));
  }

  /**
   *
   * @param configObject {
   *     redirectUri - The url the SSO server should redirect to after any successful action
   *     brand - SFLY, TP, LT, etc.
   * }
   *
   */
  showCCPA(configObject) {
    window.location.assign(urlHelper(BASE_URL_SSO + '/ccpa', configObject));
  }

  /**
   * Redirect to the sso server for logout. Takes a redirect url to redirect to or redirects to the sso
   * sign in page after logout.
   *
   * @param configObject {
   *     redirectUri
   * }
   *
   */
  signout(configObject) {
    // Pass an empty string to the urlHelper to not trigger an auto redirectUri param.
    if (configObject && !configObject.redirectUri) {
      configObject.redirectUri = '';
    } else if (!configObject) {
      configObject = { redirectUri: '' };
    }
    window.location.assign(urlHelper(BASE_URL_SSO + '/logout', configObject));
  }

  /**
   * Navigates to the change password view on the sso server
   * @param configObject
   */
  changePassword(configObject) {
    window.location.assign(
      urlHelper(BASE_URL_SSO + '/changepassword', configObject),
    );
  }

  /**
   * Send a user through the brand migration flow
   */
  migrateBrandAccount(configObject) {
    window.location.assign(urlHelper(BASE_URL_SSO + '/migrate', configObject));
  }

  /**
   * Purges the in memory storage the AuthClient uses to store the Cognito tokens and resyncs the tokens from
   * the SSO tokens endpoint. This is usefully for Single Page Apps that need to "re-check" the initial login
   * status after the SPA is loaded and a user logs in on another tab or from another brand site.
   * @returns {Promise<any>}
   */
  resyncTokens() {
    MyStorage.clear();
    return new Promise((resolve, reject) => {
      MyStorage.sync()
        .then(() => {
          this.getIdToken()
            .then((t) => {
              resolve(t);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  /**
   * Gets the current user information from the cognito sdk and returns a Promise.
   * @returns {Promise<any>}
   */
  getIdToken(verbose) {
    // If we have an unresolved promise for this object return it.
    if (this.getIdTokenPromise) {
      return this.getIdTokenPromise;
    }
    window.resyncTokens = this.resyncTokens;
    // Create a cache for the promise since calling this 15 times at once causes server calls
    this.getIdTokenPromise = new Promise((resolve, reject) => {
      initiateInterceptor();
      Auth.currentAuthenticatedUser()
        .then((e) => {
          if (e.signInUserSession && e.signInUserSession.idToken) {
            const payload = e.signInUserSession.idToken.payload;
            if (payload) {
              if (this.currentUser === null) {
                this.currentUser = {
                  given_name: payload.given_name,
                  family_name: payload.family_name,
                  email: payload.email,
                  groups: normalizeCognitoGroups(payload['cognito:groups']),
                  socialProviders: normalizeCognitoGroups(
                    payload['cognito:groups'],
                    true,
                  ),
                  cup_id: payload['cognito:username'],
                  user_type: payload['user_type'],
                  ...(payload['guest_email'] && {
                    guest_email: payload['guest_email'],
                  }),
                };

                // Append the brand UIDs if they exist in the payload
                BRAND_UID_KEYS.forEach((k) => {
                  if (payload[k]) {
                    this.currentUser[k] = payload[k];
                  }
                });
              }
              // Set the last auth time from the payload for isElevated calculation
              this.authTime = payload.auth_time;

              // Set refresh token to give to the app server
              if (this.refreshToken === null) {
                this.refreshToken = e.signInUserSession.refreshToken.token;
                // Start loading the cookie iframe that tells if the user logs out.
                this._checkSsoCookies();
              }

              // Extend cookies on the SSO server
              if (MyStorage.getTokenData()['persistUserLogin'] !== 'on') {
                let lastExtended = localStorage.getItem(
                  COGNIO_TOKENS_LAST_EXTENDED_AT,
                );
                // The condition prevents calling extendTokens endpoint for subsequent getIdToken calls from client.
                if (
                  lastExtended !== undefined &&
                  new Date().getTime() - lastExtended > WAIT_TO_EXTEND_TOKENS
                ) {
                  this._extendCognitoCookies();
                }
                if (lastExtended === undefined) {
                  // Initially last extended should be when the app was loaded for first time.
                  localStorage.setItem(
                    COGNIO_TOKENS_LAST_EXTENDED_AT,
                    new Date().getTime(),
                  );
                }
              }
            }

            // if verbose we will return the whole freaking thing. See getSignInUserSession()
            if (verbose) {
              resolve(e.signInUserSession);
            } else {
              // Otherwise we follow the 99% rule and return the only thing they need
              resolve(e.signInUserSession.idToken);
            }
          } else {
            // Get a transId to link errors from the client to splunk
            const transId = getTransactionId();
            logError(ClientErrors.INVALID_COGNITO_SESSION, null, transId);
            reject(
              getStandardErrorObject(
                ClientErrors.INVALID_COGNITO_SESSION,
                null,
                transId,
              ),
            );
          }
        })
        .catch((e) => {
          if (typeof e === 'string' && e === 'The user is not authenticated') {
            // This is a passive error since it is valid to be logged
            reject(getStandardErrorObject(ClientErrors.NOT_LOGGED_IN));
          } else if (
            (typeof e === 'string' &&
              e.toLowerCase() === 'unauthorized token in degraded mode') ||
            (typeof e.message === 'string' &&
              e.message.toLowerCase() ===
                'unauthorized token in degraded mode' &&
              (e.code === '13016' || e.code === 13016))
          ) {
            const url = BASE_URLS[ENV].token.sfly;
            console.log('condition enter', url);
            this.signout({ redirectUri: url });
          } else {
            logError(ClientErrors.CURRENT_AUTH_USER_EXCEPTION, e);
            reject(
              getStandardErrorObject(ClientErrors.CURRENT_AUTH_USER_EXCEPTION),
            );
          }
          console.log('catch event', e);
        })
        .finally(() => {
          // sets data layers for tracking software
          try {
            Trackers(this);
          } catch (err) {
            const transId = getTransactionId();
            logError(ClientErrors.TRACKER_FAILED, null, transId);
          }

          // reset the promise cache
          this.getIdTokenPromise = null;
        });
    });

    // return the newly created getIdTokenPromise
    return this.getIdTokenPromise;
  }

  /**
   * Get the full cognito user session with idToken, accessToken, and refreshToken for clients like Make My Book.
   * @returns {Promise<any>}
   */
  getSignInUserSession() {
    return this.getIdToken(true);
  }

  /**
   * Helper method to configure the auth client once we set the object from the server or from the fall back configs
   * @private
   */
  _configAuth() {
    Auth.configure({
      userPoolId: this.iamConfig.userPoolId,
      userPoolWebClientId: this.iamConfig.appClientId,
      region: this.iamConfig.region,
      storage: MyStorage,
    });
    Auth.userPool.client.endpoint = this.iamConfig.endPointUrl;
  }

  /**
   * Extends the http session on the shutterfly website.
   * @private
   */
  _extendHttpSession() {
    // Check to see if we set a timeout to stop the query call
    if (this._shouldIgnoreAppServerQueryCall()) {
      return;
    }

    fetch(`${this._getServerSessionBaseUrl()}/userstate/query.sfly`)
      .then(() => {
        logInfo('EXTEND_HTTP_SESSION_CALL');
        localStorage.setItem(
          HTTP_SESSION_LAST_EXTENDED_AT,
          new Date().getTime().toString(),
        );
      })
      .catch((e) => {
        // If we receive an error from the app server we should stop retrying for a few minutes, so we set a time to check in local storage
        localStorage.setItem(
          QUERY_APP_SERVER_TIMEOUT,
          new Date().getTime().toString(),
        );
        // Log the error to splunk for monitoring
        logError(ClientErrors.APP_SERVER_SESSION_QUERY, e, null);
      });
  }

  /**
   * Calls the check endpoint to extend the cookie life on the app server for non persistent users.
   * @private
   */
  _extendCognitoCookies() {
    fetch(`${getTokenBaseUrlByHostName()}/v3/oauth2/sso/extend`, {
      credentials: 'include',
    })
      .then(() => {
        localStorage.setItem(
          COGNIO_TOKENS_LAST_EXTENDED_AT,
          new Date().getTime(),
        );
      })
      .catch((e) => {
        logError(ClientErrors.EXTEND_PERSIST_COOKIE_ERROR, e, null);
      });
  }

  /**
   * Checks to see if we logged out from some other tab.
   * @private
   */
  _checkSsoCookies() {
    // reload iFrame to check cookies from the window.postMessage in the cookie.html
    if (this.refreshToken) {
      document
        .getElementById(COOKIE_IFRAME_ID)
        .contentWindow.location.replace(
          getTokenBaseUrlByHostName() + COOKIE_IFRAME_HTML,
        );
      setTimeout(() => {
        this._checkSsoCookies();
      }, POLL_COOKIE_EXISTENCE);
    }
  }

  _getServerSessionBaseUrl() {
    let baseUrl = BASE_URL_WWW;
    if (window.location.hostname.indexOf('tinyprints.com') > -1) {
      baseUrl = BASE_URL_TP;
    } else if (window.location.hostname.indexOf('share.') > -1) {
      baseUrl = BASE_URL_SHARE;
    }
    return baseUrl;
  }

  _shouldIgnoreAppServerQueryCall() {
    // Check to see if we timed this out before proceeding to query the app server
    let queryTimeout = localStorage.getItem(QUERY_APP_SERVER_TIMEOUT);
    if (
      queryTimeout !== undefined &&
      new Date().getTime() - queryTimeout < QUERY_APP_SERVER_TIMEOUT_DURATION
    ) {
      return true;
    }
    return false;
  }

  async _recursiveResyncTokens(currentCall) {
    currentCall = currentCall + 1;
    const MAX_ATTEMPTS = 10;
    const wait = function () {
      return new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
    };
    if (currentCall <= MAX_ATTEMPTS) {
      try {
        const tokens = await this.resyncTokens();
        if (tokens) return { tokens };
      } catch (error) {
        await wait();
        const tokens = await this._recursiveResyncTokens(currentCall);
        if (tokens) return { tokens };
      }
    } else {
      throw new Error(
        `Recursive resync tokens failed after ${MAX_ATTEMPTS} attempts`,
      );
    }
  }
}

window._sflyAuthClient = new AuthClient();
