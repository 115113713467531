import {
  setCookie,
  getStandardErrorObject,
  getTransactionId,
  logError,
  getTokenBaseUrlByHostName,
} from './Utils';
import { ClientErrors } from './ClientErrors';

// The map of tokens we receive from the SSO tokens endpoints
let tokenData = {};

export class MyStorage {
  static getTokenData() {
    return tokenData;
  }

  static setItem(key, value) {
    tokenData[key] = value;
    return tokenData[key];
  }

  static getItem(key) {
    return tokenData[key];
  }

  static removeItem(key) {
    delete tokenData[key];
  }

  static clear() {
    tokenData = {};
    MyStorage.tokenSyncPromise = null;
  }

  static sync() {
    if (!MyStorage.tokenSyncPromise) {
      MyStorage.tokenSyncPromise = new Promise((resolve, reject) => {
        fetch(`${getTokenBaseUrlByHostName()}/v3/oauth2/sso/sync`, {
          credentials: 'include',
          headers: {
            'content-type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0',
          },
        })
          .then((result) => {
            if (result.status === 200) {
              result.json().then((data) => {
                data.forEach((token) => {
                  // We set the persistUserLogin cookie in the client domain for legacy purposes on shutterfly.com and tinyprints.com
                  // TODO: Do we really need to set this for www.shutterfly.com after we decouple the HTTP session? Check After Q4 2020.
                  if (token.name === 'persistUserLogin') {
                    const d = new Date();
                    if (token.value === 'off') {
                      d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
                    } else {
                      // 450 days (this is what is in the SPA)
                      d.setTime(d.getTime() + 450 * 24 * 60 * 60 * 1000);
                    }
                    let host = window.location.hostname;
                    const match = host.match(/(dev|beta|stage|kappa)/i);
                    if (match) {
                      host = host.substring(match.index);
                    } else if (host.indexOf('.shutterfly.com')) {
                      host = '.shutterfly.com';
                    } else if (host.indexOf('.tinyprints.com')) {
                      host = '.tinyprints.com';
                    }
                    setCookie(token.name, token.value, d, host);
                  }
                  MyStorage.setItem(
                    decodeURIComponent(token.name),
                    decodeURIComponent(token.value),
                  );
                });
                resolve();
              });
            }
            if (result.status !== 200) {
              const transId = getTransactionId();
              logError(ClientErrors.TOKEN_SYNC, result, transId);
              resolve();
            }
          })
          .catch((e) => {
            // Get a transId to link errors from the client to splunk
            const transId = getTransactionId();
            logError(
              ClientErrors.TOKEN_SYNC,
              { ...e, ...(e.response ?? {}) },
              transId,
            );
            reject(getStandardErrorObject(ClientErrors.TOKEN_SYNC, transId));
          });
      });
    }
    return MyStorage.tokenSyncPromise;
  }
}
