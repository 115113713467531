import createLogger from '@sfly/aurora-js-logger';

// NOTE: we have "production" and "prod" configs since some clients pass one or the other. =(

/**
 * Client API Codes
 */
export const API_KEY_API2 = {
  dev: 'djOlaaty605xAgYG2jOc9MwuwWtJ8NgG',
  beta: 'djOlaaty605xAgYG2jOc9MwuwWtJ8NgG',
  kappa: 'djOlaaty605xAgYG2jOc9MwuwWtJ8NgG',
  stage: 'djOlaaty605xAgYG2jOc9MwuwWtJ8NgG',
  production: 'XDw7T0ipwT8AcFBtuwwlAUXoYWOgiAkL',
  prod: 'XDw7T0ipwT8AcFBtuwwlAUXoYWOgiAkL',
};
export const API_KEY_API3 = {
  dev: 'd9ylKEVogc9QtRmDnJyKFgk4DdK8iDG7',
  beta: 'd9ylKEVogc9QtRmDnJyKFgk4DdK8iDG7',
  kappa: 'd9ylKEVogc9QtRmDnJyKFgk4DdK8iDG7',
  stage: '6aGAIEelXSZ6AIt1KY7CJIiBCEq7LWy0',
  production: 'd9ylKEVogc9QtRmDnJyKFgk4DdK8iDG7',
  prod: 'd9ylKEVogc9QtRmDnJyKFgk4DdK8iDG7',
};
/**
 * Client API Codes
 */
export const API_KEY_API = {
  dev: 'XbnTgkZgRwKyNX9a90EdGGTBhvc6IXpk',
  beta: 'XbnTgkZgRwKyNX9a90EdGGTBhvc6IXpk',
  kappa: 'ms1BRq803Y0JGD7vJT3XDaASIdYD7S5L',
  stage: 'XbnTgkZgRwKyNX9a90EdGGTBhvc6IXpk',
  production: 'xbqGCOubdkYYypwETOHerYcReO5MUYdm',
  prod: 'xbqGCOubdkYYypwETOHerYcReO5MUYdm',
};

/**
 * Base URL for the sso server and other service endpoints
 *
 * @type {string}
 */
export const BASE_URLS = {
  dev: {
    token: {
      sfly: 'https://accounts-service3.dev.shutterfly.com',
      // sfly: 'https://identity-api3.dev.shutterfly.com/identity',
      tp: 'https://accounts.dev.tinyprints.com',
      mlt: 'https://accounts3-dev.lifetouch.net',
      preschools: 'https://accounts3-dev.lifetouch.net',
      prestige: 'https://accounts3-dev.lifetouch.net',
      community: 'https://accounts3-dev.lifetouch.net',
      ltsmiles: 'https://accounts3-dev.lifetouch.net',
      mycollection: 'https://accounts3-dev.lifetouch.net',
    },
    sso: 'https://accounts3.dev.shutterfly.com',
    api2: 'https://api2.dev.shutterfly.com',
    www: 'https://www.dev.shutterfly.com',
    tp: 'https://www.dev.tinyprints.com',
    share: 'https://share.dev.shutterfly.com',
    sflyidentity: 'https://identity-int3.dev.shutterfly.com',
  },
  beta: {
    token: {
      sfly: 'https://accounts.beta.shutterfly.com',
      tp: 'https://accounts.beta.tinyprints.com',
      mlt: 'https://accounts-beta.lifetouch.net',
      preschools: 'https://accounts-beta.d.applicationx.net',
      prestige: 'https://accounts-beta.lifetouch.net',
      community: 'https://accounts-beta.lifetouch.net',
      ltsmiles: 'https://accounts-beta.d.applicationx.net',
      mycollection: 'https://accounts-beta.lifetouch.net',
    },
    sso: 'https://accounts.beta.shutterfly.com',
    api2: 'https://api2.beta.shutterfly.com',
    www: 'https://www.beta.shutterfly.com',
    tp: 'https://www.beta.tinyprints.com',
    share: 'https://share.beta.shutterfly.com',
    sflyidentity: 'https://identity-int3.dev.shutterfly.com',
  },
  kappa: {
    token: {
      sfly: 'https://accounts.kappa.shutterfly.com',
      tp: 'https://accounts.kappa.tinyprints.com',
      mlt: 'https://accounts-kappa.lifetouch.net',
      preschools: 'https://accounts-kappa.d.applicationx.net',
      prestige: 'https://accounts-kappa.lifetouch.net',
      community: 'https://accounts-kappa.lifetouch.net',
      ltsmiles: 'https://accounts-kappa.d.applicationx.net',
      mycollection: 'https://accounts-kappa.lifetouch.net',
    },
    sso: 'https://accounts.kappa.shutterfly.com',
    api2: 'https://api2.kappa.shutterfly.com',
    www: 'https://www.kappa.shutterfly.com',
    tp: 'https://www.kappa.tinyprints.com',
    share: 'https://share.kappa.shutterfly.com',
    sflyidentity: 'https://identity-int3.dev.shutterfly.com',
  },
  stage: {
    token: {
      sfly: 'https://accounts-service3.stage.shutterfly.com',
      tp: 'https://accounts.stage.tinyprints.com',
      mlt: 'https://accounts3-stage.lifetouch.net',
      preschools: 'https://accounts3-stage.d.applicationx.net',
      prestige: 'https://accounts3-stage.lifetouch.net',
      community: 'https://accounts3-stage.lifetouch.net',
      ltsmiles: 'https://accounts3-stage.d.applicationx.net',
      mycollection: 'https://accounts3-stage.lifetouch.net',
    },
    sso: 'https://accounts3.stage.shutterfly.com',
    api2: 'https://api2.stage.shutterfly.com',
    www: 'https://www.stage.shutterfly.com',
    tp: 'https://www.stage.tinyprints.com',
    share: 'https://share.stage.shutterfly.com',
    sflyidentity: 'https://identity-int3.stage.shutterfly.com',
  },
  production: {
    token: {
      sfly: 'https://accounts.shutterfly.com',
      tp: 'https://accounts.tinyprints.com',
      mlt: 'https://accounts.lifetouch.com',
      preschools: 'https://accounts.preschoolsmiles.com',
      prestige: 'https://accounts.prestigeportraits.com',
      community: 'https://accounts.lifetouch.com',
      ltsmiles: 'https://accounts.lifetouchsmiles.com',
      mycollection: 'https://accounts.lifetouch.com',
    },
    sso: 'https://accounts.shutterfly.com',
    api2: 'https://api2.shutterfly.com',
    www: 'https://www.shutterfly.com',
    tp: 'https://www.tinyprints.com',
    share: 'https://share.shutterfly.com',
    sflyidentity: 'https://identity-int3.dev.shutterfly.com',
  },
  prod: {
    token: {
      sfly: 'https://accounts.shutterfly.com',
      tp: 'https://accounts.tinyprints.com',
      mlt: 'https://accounts.lifetouch.com',
      preschools: 'https://accounts.preschoolsmiles.com',
      prestige: 'https://accounts.prestigeportraits.com',
      community: 'https://accounts.lifetouch.com',
      ltsmiles: 'https://accounts.lifetouchsmiles.com',
      mycollection: 'https://accounts.lifetouch.com',
    },
    sso: 'https://accounts.shutterfly.com',
    api2: 'https://api2.shutterfly.com',
    www: 'https://www.shutterfly.com',
    tp: 'https://www.tinyprints.com',
    share: 'https://share.shutterfly.com',
    sflyidentity: 'https://identity-int3.dev.shutterfly.com',
  },
};

export const IAM_FALLBACK_CONFIGS = {
  dev: {
    appClientId: '7jolpe9o8hgbe25vk1j7uushir',
    userPoolId: 'us-east-1_0AuXDy6NQ',
    region: 'us-east-1',
    endPointUrl: 'https://accounts-service3.dev.shutterfly.com/v3/oauth2',
    sflyEndPointUrl:
      'https://api2.dev.shutterfly.com/usersvc/api/v1/authenticate',
    socialEnabled: false,
    persistenceEnabled: true,
    elevationEnabled: true,
    elevationDuration: 86400,
  },
  beta: {
    appClientId: '2go8vi75ukffvs0o6rcuostbn8',
    userPoolId: 'us-east-1_2IwUqV1Ig',
    region: 'us-east-1',
    endPointUrl: 'https://api2.beta.shutterfly.com/usersvc/api/v1/authenticate',
    sflyEndPointUrl:
      'https://api2.beta.shutterfly.com/usersvc/api/v1/authenticate',
    socialEnabled: false,
    persistenceEnabled: true,
    elevationEnabled: true,
    elevationDuration: 86400,
  },
  kappa: {
    appClientId: '5tbuap46oflh5b619ju78d11ci',
    userPoolId: 'us-east-1_H4njTpOTJ',
    region: 'us-east-1',
    endPointUrl:
      'https://api2.kappa.shutterfly.com/usersvc/api/v1/authenticate',
    socialEnabled: false,
    persistenceEnabled: true,
    elevationEnabled: true,
    elevationDuration: 86400,
  },
  stage: {
    appClientId: '279bgt6gacnhmn9ukgu63qquh3',
    userPoolId: 'us-east-1_ZxZlA5Pgp',
    region: 'us-east-1',
    endPointUrl: 'https://accounts-service3.stage.shutterfly.com/v3/oauth2',
    sflyEndPointUrl:
      'https://api2.stage.shutterfly.com/usersvc/api/v1/authenticate',
    socialEnabled: false,
    persistenceEnabled: true,
    elevationEnabled: true,
    elevationDuration: 86400,
  },
  production: {
    appClientId: 't8oiif52mece6bleeas2pof0n',
    userPoolId: 'us-east-1_TmHzoQ69j',
    region: 'us-east-1',
    endPointUrl: 'https://api2.shutterfly.com/usersvc/api/v1/authenticate',
    socialEnabled: false,
    persistenceEnabled: true,
    elevationEnabled: true,
    elevationDuration: 86400,
  },
  prod: {
    appClientId: 't8oiif52mece6bleeas2pof0n',
    userPoolId: 'us-east-1_TmHzoQ69j',
    region: 'us-east-1',
    endPointUrl: 'https://api2.shutterfly.com/usersvc/api/v1/authenticate',
    socialEnabled: false,
    persistenceEnabled: true,
    elevationEnabled: true,
    elevationDuration: 86400,
  },
};

export let ENV =
  window.sflySsoConfig && window.sflySsoConfig.env
    ? window.sflySsoConfig.env.toLowerCase()
    : process.env.ENV;
export const BASE_URLS_TOKEN = BASE_URLS[ENV].token;
export const BASE_URL_SSO = BASE_URLS[ENV].sso;
export const BASE_URL_API2 = BASE_URLS[ENV].api2;
export const BASE_URL_WWW = BASE_URLS[ENV].www;
export const BASE_URL_TP = BASE_URLS[ENV].tp;
export const BASE_URL_SHARE = BASE_URLS[ENV].share;
export const BASE_URL_GUEST_API = BASE_URLS[ENV].sflyidentity;

export const IAM_CONFIG_FALLBACK = IAM_FALLBACK_CONFIGS[ENV];

const PROVIDERS = {
  FACEBOOK: 'fb',
  GOOGLE: 'google',
  APPLE: 'apple',
};

/**
 * Main client logger for splunk
 */
const globalLogData = {};
// Ignore eslint error for Webpack.DefinePlugin environment variables
if (process.env.CI_BUILD_UUID) {
  globalLogData.appVersion = process.env.CI_BUILD_UUID;
} else {
  globalLogData.appVersion = 'local-dev-version';
}

export const logger = createLogger({
  name: 'sfly-sso-client',
  globalLogData,
  enableNRD: true,
  batchInterval: 1000,
  batchSize: 10,
  env: process.env.ENV,
});

/**
 * Helper method to add query params to the URLs
 *
 * @returns {*}
 */
export function urlHelper(url, configObject) {
  // redirectUri, brand, source, additionalQueryStringParams
  const urlParams = [];

  // Clone the config so we can alter it and not mess up the original.
  const config = Object.assign({}, configObject);
  if (config) {
    // If no redirectUri is passed we will use the current window location
    if (config.redirectUri || config.redirectUri === '') {
      // Passing an empty string stops the auto redirect back to the page and allows the default SPA actions
      // to kick in. This is the default behavior for the signout() call.
      if (config.redirectUri.length > 0) {
        urlParams.push(`redirectUri=${encodeURIComponent(config.redirectUri)}`);
      }
      // Delete so we don't add it twice
      delete config.redirectUri;
    } else {
      urlParams.push(`redirectUri=${encodeURIComponent(window.location.href)}`);
    }

    // Lifetouch Migration changeAccountUri
    if (config.changeAccountUri || config.changeAccountUri === '') {
      if (config.changeAccountUri.length > 0) {
        urlParams.push(
          `changeAccountUri=${encodeURIComponent(config.changeAccountUri)}`,
        );
      }
      // Delete so we don't add it twice
      delete config.changeAccountUri;
    }

    for (let i in config) {
      if (
        typeof config[i] === 'string' ||
        typeof config[i] === 'boolean' ||
        typeof config[i] === 'number'
      ) {
        urlParams.push(`${i}=${config[i]}`);
      }
    }
  } else {
    urlParams.push(`redirectUri=${encodeURIComponent(window.location.href)}`);
  }

  return `${url}?${urlParams.join('&')}`;
}

/**
 * Get a cookie (use to check feature flag cookie)
 * @param cookieName
 * @returns {*}
 */
export function getCookieValue(cookieName) {
  const match = document.cookie.match(
    new RegExp('(^| )' + cookieName + '=([^;]+)'),
  );
  if (match) {
    return match[2];
  }
  return null;
}

export function setCookie(cookieName, cookieVal, exp, domain) {
  const expires = 'expires=' + exp.toUTCString();
  if (domain) {
    document.cookie =
      cookieName +
      '=' +
      cookieVal +
      ';' +
      expires +
      ';domain=' +
      domain +
      ';path=/';
  } else {
    document.cookie = cookieName + '=' + cookieVal + ';' + expires + ';path=/';
  }
}

// export function isValidUrlForSessionRestart(href) {
//     let isValid = true;
//     if(href.indexOf('shutterfly.com') > -1 && href.indexOf('signout/') > -1){
//         isValid = false;
//     }
//     return isValid;
// }

/**
 * Helper methodd that returns the proper token URL host to get us around cross domain cookie syncing.
 * We must extend tokens and sync tokens from the proper host to avoid security issues in Safari and IE 11.
 * @returns {string|*}
 */
export function getTokenBaseUrlByHostName() {
  const { hostname } = window.location;
  // Default token base url for shutterfly.com domain (Chrome & Not so strict browsers)
  let baseUrl = BASE_URLS_TOKEN.sfly;
  if (hostname.indexOf('tinyprints.com') !== -1) {
    baseUrl = BASE_URLS_TOKEN.tp;
  } else if (
    hostname.indexOf('my.lifetouch.com') !== -1 ||
    hostname.indexOf('my.lifetouch.net') !== -1 ||
    hostname.indexOf('my3.lifetouch.net') !== -1
  ) {
    baseUrl = BASE_URLS_TOKEN.mlt;
  } else if (
    hostname.indexOf('community.lifetouch.com') !== -1 ||
    hostname.indexOf('community.lifetouch.net') !== -1 ||
    hostname.indexOf('community3.lifetouch.net') !== -1
  ) {
    baseUrl = BASE_URLS_TOKEN.community;
  } else if (
    hostname.indexOf('prestigeportraits.com') !== -1 ||
    hostname.indexOf('solo-r.lifetouch.net') !== -1 ||
    hostname.indexOf('solo-r3.lifetouch.net') !== -1
  ) {
    baseUrl = BASE_URLS_TOKEN.prestige;
  } else if (
    hostname.indexOf('lifetouchsmiles.com') !== -1 ||
    hostname.indexOf(
      `https://ltsmiles-${ENV === 'stage' ? 'qa' : ENV}.d.applicationx.net`,
    ) !== -1
  ) {
    // For preschools and ltsmiles host name is same at the end. Added full host name for ltsmiles(Above)
    baseUrl = BASE_URLS_TOKEN.ltsmiles;
  } else if (
    hostname.indexOf('preschoolsmiles.com') !== -1 ||
    hostname.indexOf('d.applicationx.net') !== -1 ||
    hostname.indexOf('preschoolsmiles3.lifetouch.net') !== -1
  ) {
    baseUrl = BASE_URLS_TOKEN.preschools;
  } else if (
    hostname.indexOf('mycollection.lifetouch.com') !== -1 ||
    hostname.indexOf('mycollection3.lifetouch.net') !== -1
  ) {
    baseUrl = BASE_URLS_TOKEN.mycollection;
  }
  return baseUrl;
}

/**
 * Normalizes the Cognito Groups into values that are more human readable.
 * @param groups
 * @param socialOnlyAccount
 * @returns {Array}
 */
export function normalizeCognitoGroups(groups, socialOnlyAccount = false) {
  const normalized = [];
  if (groups && groups.length > 0) {
    for (let i = 0; i < groups.length; i++) {
      let groupName = groups[i].toLowerCase();
      if (groupName.indexOf(PROVIDERS.FACEBOOK) !== -1) {
        normalized.push('Facebook');
      } else if (groupName.indexOf(PROVIDERS.GOOGLE) !== -1) {
        normalized.push('Google');
      } else if (groupName.indexOf(PROVIDERS.APPLE) !== -1) {
        normalized.push('Apple');
      } else if (socialOnlyAccount === false) {
        /**
         *  This uitility is being used at multiple place .
         *  We don't need to include cognito:user group in socialProviders
         */
        normalized.push(groups[i]);
      }
    }
  }
  return normalized;
}

/**
 * Get a made up sudo-random transaction id for logging and error linkage
 * @returns {string}
 */
export function getTransactionId() {
  const d = new Date();
  return (
    Math.random().toString(36).substring(2).toUpperCase() + '_' + d.getTime()
  );
}

export function getStandardErrorObject(clientError, transId) {
  return { error: clientError, transId: transId };
}

export function logError(clientError, realError, transId) {
  logger.error({
    appContext: {
      msg: `${clientError.code} - ${clientError.splunkLabel}`,
      error: JSON.stringify(clientError),
      errorStringify: JSON.stringify(realError),
      realError: realError,
      url: window.location,
      transId: transId ? transId : '',
    },
  });
  logger.dispatchLogs();
}

export function logInfo(msg) {
  logger.info({
    appContext: {
      msg,
    },
  });
  logger.dispatchLogs();
}

export const WINDOW_FEATURES_VALID_PROPERTIES = [
  'popup',
  'width',
  'innerWidth',
  'height',
  'innerHeight',
  'left',
  'screenX',
  'top',
  'screenY',
  'noopener',
  'noreferrer',
];

export const RESYNC_TOKENS_SUCCESS_SHOW_SIGN_IN_NO_REDIRECT =
  'RESYNC_TOKENS_SUCCESS_SHOW_SIGN_IN_NO_REDIRECT';

//Initiate interceptor for authenticate call
export const initiateInterceptor = () => {
  if (!window.fetchPatched) {
    window.fetchPatched = true; // only patch window.fetch once to prevent recursion issue

    const originalFetch = fetch;
    window.fetch = async (...args) => {
      const [resource, config] = args;
      // config.credentials = 'include';
      if (
        config &&
        typeof config === 'object' &&
        config['method'] &&
        config.method === 'POST' &&
        typeof resource === 'string' &&
        resource.includes('/v3/oauth2')
      ) {
        config.headers = {
          ...(config.headers || {}),
          'Content-Type': 'application/json',
          'SFLY-Apikey': API_KEY_API[ENV],
          //'x-api-key': API_KEY_API3[ENV],
          'sfly-application-name': 'sflyssofe',
        };
      }

      // if (
      //     config &&
      //     typeof config === 'object' &&
      //     config['method'] &&
      //     config.method === 'POST' &&
      //     typeof resource === 'string' &&
      //     resource.includes('/v3/oauth2') &&
      //     config.body && config.body.includes("REFRESH_TOKEN")

      // ) {
      // //    resource=IAM_CONFIG_FALLBACK.sflyEndPointUrl
      //     await window.resyncTokens();
      // }

      const response = await originalFetch(resource, config);
      // response interceptor here
      return response;
    };
  }
};
