import {
  BASE_URL_GUEST_API,
  getStandardErrorObject,
  getTransactionId,
  logError,
} from './Utils';
import { ClientErrors } from './ClientErrors';
import uuid from 'uuid';

export function doGuestLoginApiCall(redirectUri) {
  const body = {
    globalInfo: {
      core: {
        primaryEmail: uuid(),
      },
      metadata: {},
    },
  };

  fetch(`${BASE_URL_GUEST_API}/v3/guest/account/signup`, {
    credentials: 'include',
    method: 'post',
    headers: {
      'content-type': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    },
    body: JSON.stringify(body),
  })
    .then((result) => {
      if (result.status === 201) {
        result.json().then((data) => {
          const bounceForm = document.createElement('form');
          bounceForm.setAttribute('method', 'POST');
          document.body.appendChild(bounceForm);
          bounceForm.setAttribute(
            'action',
            `${BASE_URL_GUEST_API}/v3/sso/brand/SFLY/sync`,
          );

          const bounceFormRedirectUri = document.createElement('input');
          bounceFormRedirectUri.setAttribute('type', 'hidden');
          bounceFormRedirectUri.setAttribute('name', 'redirectUri');
          bounceFormRedirectUri.setAttribute('value', redirectUri);
          bounceForm.appendChild(bounceFormRedirectUri);

          const bounceFormRefreshToken = document.createElement('input');
          bounceFormRefreshToken.setAttribute('type', 'hidden');
          bounceFormRefreshToken.setAttribute('name', 'refreshToken');
          bounceFormRefreshToken.setAttribute('value', data.refresh_token);
          //bounceFormRefreshToken.setAttribute('value', refreshToken);
          bounceForm.appendChild(bounceFormRefreshToken);

          // Set clockDrift for all cases
          const bounceFormClockDrift = document.createElement('input');
          bounceFormClockDrift.setAttribute('type', 'hidden');
          bounceFormClockDrift.setAttribute('name', 'clockDrift');
          bounceFormClockDrift.setAttribute('value', 0);
          bounceForm.appendChild(bounceFormClockDrift);

          const bounceFormMaxAgeInSeconds = document.createElement('input');
          bounceFormMaxAgeInSeconds.setAttribute('type', 'hidden');
          bounceFormMaxAgeInSeconds.setAttribute('name', 'maxAgeInSeconds');
          bounceFormMaxAgeInSeconds.setAttribute('value', 38880000);
          bounceForm.appendChild(bounceFormMaxAgeInSeconds);

          // Submit the form to post to the server
          bounceForm.submit();
        });
      }
      if (result.status !== 201) {
        const transId = getTransactionId();
        logError(ClientErrors.GUEST_LOGIN_FAILED, result, transId);
        resolve();
      }
    })
    .catch((e) => {
      // Get a transId to link errors from the client to splunk
      const transId = getTransactionId();
      logError(
        ClientErrors.GUEST_LOGIN_FAILED,
        { ...e, ...(e.response ?? {}) },
        transId,
      );
      reject(getStandardErrorObject(ClientErrors.GUEST_LOGIN_FAILED, transId));
    });
}
