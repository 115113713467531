export const Events = {
  EVAL_READY: 'sflyAuthEvalReady',
  INIT_READY: 'sflyAuthInitReady',
  AUTH_CLIENT_LOADED: 'sflyAuthLibLoaded',
  SESSION_LOGOUT: 'sflySessionLogout', // need to check
  TRIGGER_NO_REDIRECT_SIGN_IN: 'ssoSignInNoRedirect',
  CLOSE_SIGN_IN_POPUP: 'sflyCloseSignInPopUp',
  SIGN_IN_POPUP_FORM_SUBMITTED_SUCCESS: 'ssoSigninFormSubmittedSuccess',
  SIGN_IN_POPUP_FORM_SUBMITTED_FAILURE: 'ssoSigninFormSubmittedFailure',
};
